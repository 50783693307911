import { useEffect, useState } from "react";

const App = () => {
  const [demoProjects, setDemoProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("projects.json")
      .then((res) =>
        res.json().then(({ projects }) => {
          setDemoProjects(projects);
          setLoading(false);
        })
      )
      .catch(() => setLoading(false));
  }, []);

  return (
    <div className="app-container">
      <a href="https://thulane.co.za" target="_blank">
        <div className="logo-container">
          <p className="full-port-btn-text">See full portfolio</p>
          <img className="logo" src="/logo192.png" alt="logo" />
        </div>
      </a>

      <h1 className="heading">
        {" "}
        <span>Thulane's</span> <br /> Demo Projects
      </h1>

      {loading && <h2 className="demo-projects-loading">Loading Projects</h2>}

      {demoProjects.length && !loading && (
        <div className="projects-container">
          {demoProjects.map(({ link, name }) => (
            <a key={name} className="project" href={link} target="_blank">
              {name}
            </a>
          ))}
        </div>
      )}

      {!loading && !demoProjects.length && (
        <h2 className="demo-projects-loading">
          There are currently no demo projects
        </h2>
      )}

      <div className="footer">
        <a href="https://thulane.co.za">
          <button className="all-prj-btn">Open All Projects</button>
        </a>
        <p className="all-prjcs-text">*external link</p>
      </div>
    </div>
  );
};

export default App;
